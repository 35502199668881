<template>
  <div id="appa">
    <div class="bj-div">
      <img src="../../images/top.png" style="width:100%" alt="">
      <h1>某某机电制造有限公司股权变更登记案</h1>
      <div class="link"></div>
      <div class="title">基本信息</div>
      <div class="info" style="margin-top:10px">
        <div class="info-left">案号<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">(200208)号</div>
      </div>
      <div class="info">
        <div class="info-left">案由<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">财产纠纷</div>
      </div>
      <div class="info">
        <div class="info-left">开庭时间<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">2022年9月1号</div>
      </div>
      <div class="info">
        <div class="info-left">庭审地点<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">周村区人民法院</div>
      </div>



      <div class="title">审判组织成员</div>
      <div class="info" style="margin-top:10px">
        <div class="info-left">承办人<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">任律师</div>
      </div>
      <div class="info" >
        <div class="info-left">审判长<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">赵庭长</div>
      </div>
      <div class="info" >
        <div class="info-left">书记员<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">钱某</div>
      </div>
      <div class="info" >
        <div class="info-left">会议议员<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">孙某</div>
      </div>

      <div class="title">诉讼参与人</div>
      <div class="info" style="margin-top:10px">
        <div class="info-left">上诉人<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">李某</div>
      </div>
      <div class="info" >
        <div class="info-left">被告人<span></span></div>
        <div class="info-c">:</div>
        <div class="info-right">周某</div>
      </div>

      <div class="title">案件摘要</div>
      <div class="text">2015年12月28日，工商登记变更手续完成后，徐某、王某等人拒绝签署原来律师起草的退股协议书，徐某、王某等也隐匿了踪迹。机电公司通过其他方式了解，山东某某管道公司连年亏损，根本拿不出现金收购股权，致使机电公司对徐某、王某、李某某等人的动机、目的产生了怀疑，经多方打听，了解到徐某、王某既没有证券从业资格，更没有相关上市的经验。徐某曾经是某上市公司财务人员，李某某是证券公司人员，对于机电公司是否具备上市的基本条件应当很清楚，但是事先并未向机电公司明确告知，隐瞒了事实真相。2016年3月，苏某某、王某和山东某某投资有限公司派人到机电公司主张所谓股东权益，其中有几名社会闲散人员自称是隐名股东。更有甚者，徐某还指使人发函对机电公司威胁，此后又暗中指使他人张贴小字报等形式对机电公司进行敲诈，态度十分恶劣，非法占有机电公司财产的意图比较明显。


      律师认为徐某、王某等伙同他人，以非法占有其公司股权为目的，虚构自己具有运作企业新三板挂牌能力的事实，隐瞒新三板挂牌条件、管道公司严重亏损等事实，利用机电公司急需资金的心理和对于融资法律知识淡薄的情况，在办理工商登记取得股权后，将出资全部抽回占为己有而不变更股权登记，实质上非法占有了机电公司股权300万元，且涉嫌抽逃出资，数额巨大。对此情况，可以向侦查机关报案，通过刑事追赃手段，实现委托人目的。</div>

      <div class="title" style="margin-top:20px">审庭视频</div>
      <div style="height:300px;">
        <div class="video-info" style="margin-top:20px">
          <video id="myVideo" style="width: 100%;" :autoplay='true'  src="http://1300940639.vod2.myqcloud.com/98e57865vodcq1300940639/397297258602268011085678264/8beWF5poaDgA.mp4" @error="videoErrorCallback" controls></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
export default {

  data() {
    return {
      url: this.GLOBAL.localDomain,
      //token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJpbmZvIjoiUk9RS2JhUGxCRHFvNVJ6dXAySktKZTFHN2t0d2RwaUx4dUg3QXdCeWZteTlDU1Y4dnlkWDk1WW5WTndTSmR4bmEvditCWisyWFFiRERJRTJCNWJnZnRyenNwYmJIdnpaREg4RGFCZ21pOVBTamIwMGRMOEdBNnArUitldmdTc24wMXRVSllMbWpYWHdqV1pZRjhFL0xJajFnbFgwTEJtbjFQWDdUbUN1V1NKYlNBOEpPVGR5TjFmeXdabDNrUXo3VFFsd1g2bEwxU3RIb2sxY3k4VGNBcU1qMCtMWlBpLzR4b09qbmZXTlhtS3pFWjlQM3lPZHMrZEdyazA1dVlRem9oWlhuTG02bHNuQWlKTllCc1ZOdWNpT2tUUEV6OWVvRUx0VWpRVGVlNlB3cFdTV2g2cXE4ZFR4WEJSTFY5V3hIYkNwQzVnclMrRnpWckZKaWxIbmtBPT0iLCJleHAiOjE2NTU1MTc4NDB9.TnJx8jpea_GvjUxNpUmTled1CDUgYmX7oTGyTuSViX4tuUQTvxDjdMVbLBgXghn6",
      token:'',
    };
  },
  mounted(){
    this.token = this.$route.params.token? this.$route.params.token: this.token;
  },
  created() {
    document.title = '周村法院'
  },
  methods: {
    videoErrorCallback(){},
    async getList() {
      // let that = this
      // this.$axios.defaults.headers.newtech = this.token;
      // await that.$axios.post(this.url + "/open/wx/user/offer/result/list")
      // .then((response) => {
      //   this.type = response.data.data.status;
      //   let data = response.data.data;
      //   this.list = data.demandGoods;
      
      // });
    },
    
  },
};
</script>
<style lang="scss" scoped>

  #appa{
    padding: 0px;
    margin: 0px;
    .bj-div{
      border-top: 1px solid transparent;
      width: 100vw;
      min-height: 100vh;
      background: #66A5FF;
      background-image: url('../../images/bj.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      h1{
        font-size: 24px;
        margin: 20px;
        text-align: center;
        margin-top: 0px;
      
      }
      .link{
        margin: 20px;
        height: 1px;
        border-top: 2px solid #fff;
      }
      .title{
        margin:10px 20px;
        font-weight: 600;
        font-size: 20px;
      }
      .info{
        margin: 0 20px;
        height: 30px;
        display: flex;
        .info-left{
          justify-content: space-around;
          width: 70px;
          text-align:justify;
          color: #333333;
          span{
            display:inline-block;
            width:100%;
          }
        }
        .info-c{
          color: #979797;
          margin-left: 5px;
        }
        .info-right{
          flex: 1;
          margin-left: 15px;
          font-weight: 600;
          color: #2c2c2c;
          letter-spacing:3px;

        }
      }
      .video-info{
        margin:0 20px;
      }
      .text{
        margin:5px 20px;
       letter-spacing: 2px;
       color: #2c2c2c;
      }
    }
  }
</style>
