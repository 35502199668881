<template>
  <div id="app">
    
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      
    };
  },
  async created() {
    
  },
  methods: {
   
  },
};
</script>

<style>

</style>
