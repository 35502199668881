import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'//引入axios
import router from './router'
import global_ from './components/Global'
// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false
//axios.defaults.baseURL = 'http://192.168.100.200/';
axios.defaults.rejectUnauthorized = false;
Vue.axios = Vue.prototype.$axios = axios;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.prototype.GLOBAL = global_

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
