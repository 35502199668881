<script>
    const debug = true
    if(debug){
     var localDomain = 'https://v2.baiqingcity.com'  //后台接口
    }else{
      var localDomain = "http://192.168.100.166/"    //后台接口
    }

    export default{
        localDomain
    }
</script>
