import Vue from 'vue'
import VueRouter from 'vue-router'
import Guize from '../components/guize/guize.vue'
import Home from '../components/home/home.vue'
//1 注入插件
Vue.use(VueRouter)

const routes = [
    //默认首页
    {
        path: '/',
        component: Home     
    },
    //所有路由
    {
        path: '/home',
        component: Home
    },
    {
        path: '/guize',
        component: Guize
    },
]
//2 定义路由
// export default new VueRouter({
//     mode:'history',
    
    
// })

//3 创建router实例
 const Router = new VueRouter({
    routes
  })
//4 导出router实例
export default Router


